<script>
 import axios from 'axios';
// // // import Switches from "vue-switches";
// // import Swal from 'sweetalert2';
 import Swal from 'sweetalert2';
//const moment = require('moment');
// import BigNumber from 'bignumber.js'
// // import _ from "lodash";
// import { required } from "vuelidate/lib/validators";

export default {
  props: ['loadOptionDataParent'],
  components: {
  },
 
  data() {
    return {
      items: { kategori:"",
        createdBy:"",
        judulPesan:"",
        isiPesan:"",},

      fields: [

        {
          key: "kategori",
          label: "Kategori ",
          text: "Kategori ",
          value: "kategori",
          sortable: true,
          thStyle: { width: "15%" },
        },
        {
          key: "createdBy",
          label: "Dibuat Oleh",
          text: "Dibuat Oleh",
          sortable: true,
          value: "",
          thStyle: { width: "15%" },
        },
        {
          key: "judulPesan",
          label: "Judul Quick Chat",
          text: "Judul Quick Chat",
          sortable: true,
          value: "",
          thStyle: { width: "15%" },
        },
        {
          key: "isiPesan",
          label: "Diksrpsi Pesan",
          text: "Diksrpsi Pesan",
          sortable: true,
          value: "quickChatMassage",
          thStyle: { width: "45%" },
        },
        { key: 'actions', label: '',thStyle: { width: "5%" },}

      ],
      selected: null,
      selectedArray: [],
      selectedFilter:"Pembayaran",
      tabIndex:1,
      kategoriQuickChat:["Pembayaran","Q&A","Gangguan","Chat Umum"],
      
      hideFooter:true,
      newQuickChat : { 
        kategori:null,
        createdBy:"",
        judulPesan:"",
        isiPesan:"",
      },
      disabledInputAdd:{
        judulPesan:true,
        isiPesan:true,
        addButton:true
      },
      filter:"",
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      editQuickData: {},
      selectedEdit:"",
    }
  },
  async mounted() {
    await this.getData()
  },
  methods: {
    async show() {
      this.$refs.modal.show()
    },
    async EditQuick(a){
      this.selectedEdit = ""
      this.editQuickData={}
      this.editQuickData = a
      this.$refs.editModal.show()
      this.selectedEdit= this.editQuickData.kategori
    },
    async editQuickCancel(){
      this.selectedEdit = ""
      this.editQuickData={}
      this.$refs.editModal.hide()
    },
    async editQuickPatch(){
      Swal.fire({
      title: "Apakah Anda Ingin Menyimpan Perubahan?",
      text: "Data Akan Di Perbarui",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes"
      }).then(async(result) => {
        if (result.isConfirmed) {
          console.log("data",this.editQuickData);
        await axios.patch(process.env.VUE_APP_URL_FEATHERS + 'quick-chat-management/'+this.editQuickData._id,this.editQuickData).then(response => {
        Swal.fire(
            'Updated!',
            'Quick Chat Berhasil Di Perbarui',
            'success'
        )
        console.log(response);
      }).catch(error=>{
        Swal.fire(
            'Gagal!',
            error.message,
            'error'
        )
      });
         
          this.$refs.editModal.hide()
        }
});
    },
    async deleteQuick(a){
      Swal.fire({
      title: "Apakah Anda Ingin Menghapus?",
      text: "Data Akan Di Hapus Permanen",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes"
      }).then(async (result) => {
        
        if (result.isConfirmed) {
          await axios.delete(process.env.VUE_APP_URL_FEATHERS + 'quick-chat-management/'+a).then(async (response) => {
        console.log("sini", response);
        Swal.fire(
            'Sukses!',
            'Berhasil Menghapus Pesan Cepat',
            'success'
        )
          await this.getData()
      }).catch(error=>{
        Swal.fire(
            'Gagal!',
            error.message,
            'error'
        )
      });
         
        }
});
    },
    async submitAdd(){
      this.newQuickChat.createdBy=this.$store.state.authfack.user.username;

      
      await axios.post(process.env.VUE_APP_URL_FEATHERS + 'quick-chat-management',this.newQuickChat).then(response => {
        console.log("sini", response);
        Swal.fire(
            'Sukses!',
            'Berhasil Menambah Pesan Cepat',
            'success'
        )
      }).catch(error=>{
        Swal.fire(
            'Gagal!',
            error.message,
            'error'
        )
      });
      await this.getData()
      this.tabIndex--
      this.$emit('clicked', 'someValue')
    },
    async getData(){
      await axios.get(process.env.VUE_APP_URL_FEATHERS + 'quick-chat-management').then(response => { 
        this.items= response.data.data
        
      }).catch(error=>{
        Swal.fire(
            'Gagal!',
            error.message,
            'error'
        )
      });
    },
   
  },
  watch: {
    'newQuickChat.kategori': {
      handler() {
        if(this.newQuickChat.kategori != null){
          this.disabledInputAdd.judulPesan=false
        }
        else {
          this.disabledInputAdd.judulPesan=true
        }
      },
     
    },
    'newQuickChat.judulPesan': {
      handler() {
        if(this.newQuickChat.judulPesan != null && this.newQuickChat.judulPesan.length > 0 ){
          this.disabledInputAdd.isiPesan=false
        }
        else {
          this.disabledInputAdd.isiPesan=true
          this.disabledInputAdd.addButton=true
        }
      },
     
    },
    'newQuickChat.isiPesan': {
      handler() {
        if(this.newQuickChat.isiPesan != null && this.newQuickChat.isiPesan.length > 0  && this.newQuickChat.isiPesan.length > 0 ){
          this.disabledInputAdd.addButton=false
        }
        else {
          this.disabledInputAdd.addButton=true
        }
      },
     
    },
    'selected':{
      handler(){
       this.selectedArray[0]= "kategori"
       
      }
    }
  },
};
</script>
  
<template>
  <b-modal id="modal" ref="modal" centered title="Manage Quick Chat" title-class="font-18" size="xl" :hide-footer="true" >
    <div>
      <b-tabs content-class="mt-3" v-model="tabIndex"  fill>
        
        <b-tab title="List Quick Chat" active>
          <div class="table-responsive mt-4 mb-0">
            <label class="d-inline-flex align-items-center fw-normal">
            Show&nbsp;
          </label>
            <b-form-select v-model="perPage" :options="pageOptions" size="sm" class="mr-6"></b-form-select>&nbsp;entries &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            
            <b-select v-model="selected"  :options="kategoriQuickChat" >
                <template #first>
                <b-select-option :value=null>-- Semua Kategori --</b-select-option>
                </template>
              </b-select>
             <b-table striped hover ref="tableQuick"
            :items="items"
            :fields="fields"
            :key="items.quickChatMassage"
            table-class="table table-centered datatable table-card-list"
            thead-tr-class="bg-secondary"
            tbody-tr-class="custom-border"
            :filter-included-fields="selectedArray"
            :filter="selected"
            responsive="md" 
            :per-page="perPage" 
            :current-page="currentPage">
            <template #cell(actions)="row">
              <b-button @click="EditQuick(row.item)" size="sm" variant="info" class="fas fa-pen" style="margin-right: 0.313rem;" >
              </b-button>
              <b-button  @click="deleteQuick(row.item._id)" size="sm" variant="danger" class="fas fa-trash-alt">
              </b-button>

              <b-modal id="editModal" ref="editModal" size="lg" centered title="Edit Quick Chat"
              >
                <b-form>
              <b-form-group id="input-group-1" label="Kategori Quick Chat:" label-for="input-1">
              <b-select v-model="selectedEdit"  :options="kategoriQuickChat" >
                <template #first>
                <b-select-option :value=selectedEdit>{{ selectedEdit }}</b-select-option>
                </template>
              </b-select>
            </b-form-group>
            <b-form-group id="input-group-1" label="Judul Quick Chat:" label-for="input-1" >
              <b-form-input v-model ="editQuickData.judulPesan" id="input-default" size="md" placeholder="Judul Pesan..."></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-1" label="Pesan Quick Chat:" label-for="input-1">
              <b-form-textarea id="textarea-default" size="md" placeholder="Masukan Pesan..." v-model="editQuickData.isiPesan"></b-form-textarea>
            </b-form-group>
            
          </b-form>
          <template #modal-footer>
        <div class="w-100">
          
          <b-button
            variant="primary"
            size="sm"
            class="float-end"
            @click="editQuickPatch"
          >
            Simpan
          </b-button>
          <b-button
            size="sm"
            class="mr-2 float-end "
            @click="editQuickCancel"
          >
            Batal
          </b-button>
        </div>
            </template>
              </b-modal>
            </template>
            
          </b-table>
          </div>
          
        </b-tab>
        <b-tab title="Tambah Quick Chat">
          <!-- <b-form @submit="onSubmit" @reset="onReset" v-if="show"> -->
          <b-form>
    
            <b-form-group id="input-group-1" label="Kategori Quick Chat:" label-for="input-1">
              <b-form-select v-model="newQuickChat.kategori" class="mb-3" :options="kategoriQuickChat">
                <template #first>
                <b-form-select-option :value=null disabled>-- Pilih Kategori --</b-form-select-option>
                </template>
              
              </b-form-select>
            </b-form-group>
            <b-form-group id="input-group-1" label="Judul Quick Chat:" label-for="input-1" >
              <b-form-input v-model ="newQuickChat.judulPesan" id="input-default" size="md" placeholder="Judul Pesan..." :disabled="disabledInputAdd.judulPesan"></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-1" label="Pesan Quick Chat:" label-for="input-1">
              <b-form-textarea v-model="newQuickChat.isiPesan" id="textarea-default" size="md" placeholder="Masukan Pesan..." :disabled="disabledInputAdd.isiPesan"></b-form-textarea>
            </b-form-group>
           
      <b-button variant="primary" class="mt-5 float-end" @click="submitAdd()" :disabled="disabledInputAdd.addButton">
        Simpan
      </b-button>
    
          </b-form>
        </b-tab>
       
      </b-tabs>
    </div>
    
  </b-modal>


  
</template>
  