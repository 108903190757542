<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import simplebar from "simplebar-vue";
import appConfig from "@/app.config";
import modalManageQuickChat from "@/components/modal-manage-quick-chat";
import { groupData } from "./data";
import { required } from "vuelidate/lib/validators";
import axios from 'axios';
import Swal from "sweetalert2";
import moment from 'moment';
import _ from 'lodash';
import Switches from "vue-switches";
import DatePicker from 'vue2-datepicker';
import debounce from "lodash.debounce";
import Loading from 'vue-loading-overlay';

/**
 * Chat component
 */
export default {
  page: {
    title: "Chat",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    simplebar,
    modalManageQuickChat,
    Switches,
    DatePicker,
    Loading
  },
  data() {
    return {
      chatData: [],
      quickChatDataset: {
        pembayaran: 0,
        gangguan: 0,
        questionAndAnswer: 0,
        chatUmum: 0
      },
      test: "hallo",
      openTime: false,
      groupData: groupData,
      chatMessagesData: [],
      holderSelectedIdPetugas: [],
      title: "Chat",
      items: [
        {
          text: "Apps",
        },
        {
          text: "Chat",
          active: true,
        },
      ],
      submitted: false,
      form: {
        message: "",
      },
      username: "",
      member: null,
      phoneNumberSelected: null,
      replyId: "",
      data: {},
      hoverId:"",
      isChatDisabled: false,
      isChatLoading: false,
      contactProfile: {
        name: '',
        number: ''
      },
      customerId: null,
      customerApplicant: {
        name: '',
        address: '',
        phoneNumber: ''
      },
      submittedCustomer: false,
      limitContact: 10,
      limitContactChat:10,
      submittedTicket: false,
      enabled:false,
      enableCustomerApplicant: true,
      isAccordionOpen: false,
      selectedPetugas: [],
      selectedPetugasCount: 1,
      holderIdPetugas: null,
      optionsPetugas: null,
      holderUpdateAssigment: null,
      ticket: {
        problems: '',
        customerProduct: '',
        customerId: '',
        category: '',
        status: 'open',
        stage: {},
        priority: 'low',
        isCustomer: false
      },
      selectedDateTime: {
        date: null,
        time: null, // Untuk menyimpan waktu yang dipilih
      },
      listTicketProduct: [],
      loadingStateTroubleShoot: {
        oltRx: true,
        onuRx: true,
        signal: true,
        onuIP: true,
        upTime: true,
        status: true,
        result: true
      },
      choiceTicket: {
        product: null,
        category: null,
        priority: {
          text: 'Pilih',
          value: ''
        }
      },
      categoryTicketList: [
        {
          text: 'Internet',
          value: 'internet'
        },
        {
          text: 'Administrasi',
          value: 'admin'
        },
        {
          text: 'Instalasi',
          value: 'installation'
        }
      ],
      priorityTicketList: [
        {
          text: 'Low',
          value: 'low'
        },
        {
          text: 'High',
          value: 'high'
        }
      ],
      quickChatDisplay: {},
      fieldsQuickChat:[           
     
      {
        key: "judulPesan",
        label: "Judul Quick Chat",
        text: "Judul Quick Chat",
        sortable: true,
        value: "",
        thStyle: { width: "15%" },
      },
      {
        key: "isiPesan",
        label: "Diksrpsi Pesan",
        text: "Diksrpsi Pesan",
        sortable: true,
        value: "quickChatMassage",
        thStyle: { width: "45%" },
        
      },
      { 
      key: 'actions',
      label: '',
      thStyle: { width: "5%" },
      
      }
      ], 
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      cardTroubleShooting: false,
      isChecked: true,
      isMorphed: false,
      cardOptionResult:[],
      holderCheckedOpen: false,
      StateTroubleShootValue: {
        oltRx: null,
        onuRx: null,
        onuSignal: null,
        onuIP: null,
        onuUptime: null,
        onuState: null,
        result: null,
        code: null,
        checkingPayment: null,
        ticketId: null,
        stage_id: null
      },
      stateTroubleShoot: {
        oltRx: null,
        onuRx: null,
        signal: null,
        onuIP: null,
        upTime: null,
        status: null,
        result: null
      },
      tagDummy: [
      {
        name:'tag1',
        discription:'hanya untuk iseng'
      },
      {
        name:'tag2',
        discription:'hanya untuk iseng'
      },
      {
        name:'tag3',
        discription:'hanya untuk iseng'
      },
      {
        name:'tag4',
        discription:'hanya untuk iseng'
      },
      {
        name:'tag5',
        discription:'hanya untuk iseng'
      },
      {
        name:'tag6',
        discription:'hanya untuk iseng'
      },

    ],
    regexHolder: null,
    regexFilterOn: false,
    isLoadingChat:false
    }
  },
  validations: {
    form: {
      message: {
        required,
      },
    },
    customerApplicant: {
      name: {
        required
      },
      address: {
        required
      },
      phoneNumber: {
        required
      }
    },
    ticket: {
      problems: {
        required
      }
    }
  },
  async mounted() {
    this.$root.$off('chatSocket')
    this.$root.$on('chatSocket', async (args) => {
      // your code goes here
      this.getContacts()
      if (args.wa_id == this.phoneNumberSelected) {
        this.chatMessagesData = await axios.get(process.env.VUE_APP_URL_FEATHERS + 'waba-chats?waId=' + this.data.wa_id)
          .then(response => {
            // console.log(response)
            this.handleScroll()
            this.replyId = response.data.data[response.data.data.length - 1].messageEntryId
            return response.data.data
          })
      }
      // console.log('jalan', args)
    })
    var container1 = document.querySelector(
      "#chat-list .simplebar-content-wrapper"
    );
    container1.scrollTo({ top: 500, behavior: "smooth" });

    var container2 = document.querySelector(
      "#containerElement .simplebar-content-wrapper"
    );
    container2.scrollTo({ top: 500, behavior: "smooth" });

    await this.quickChatData()

  },
  created() {
    this.getContacts()
    this.debouncedWatch = debounce(async (newValue1) => {
      await this.getContactsRegex(newValue1)
    }, 200);
  },
  watch: {
    'choiceTicket.product': {
      immediate: true,
      handler(newValue) {
        if (newValue === 0 || newValue) {
          // console.log('prod', newValue)
          console.log(newValue)
          this.ticket.customerProduct = newValue.value
          this.choiceTicket.orderId= newValue.text
        }
      }
    },
    'choiceTicket.category': {
      immediate: true,
      handler(newValue) {
        if (newValue === 0 || newValue) {
          // console.log('cat', newValue)
          this.ticket.category = newValue
        }
      }
    },
    isChecked: {
      immediate: true,
      handler(newValue) {
        
        if (newValue === 0 || newValue ) {
          // this.ticket.category = newValue
          this.checkedEnableAi(this.data)
        }
        if (newValue === false) {
          
          if (this.data.enableAi !== null) {
            this.checkedEnableAi(this.data)
          }
          
        }
      }
    },
    'selectedPetugas': {
      deep: true,
      handler(value) {
        // Pastikan this.holderSelectedIdPetugas adalah array yang sesuai
        if (!Array.isArray(this.holderSelectedIdPetugas)) {
          this.holderSelectedIdPetugas = [];

        }
      

        if (this.kategoriAltInstal == true) {
          this.runTroubleShoot = true
        }

        for (let index = 0; index < value.length; index++) {
          for (let index2 = 0; index2 < this.optionsPetugas.length; index2++) {
            if (this.optionsPetugas[index2] === value[index]) {
              // Pastikan array holderSelectedIdPetugas memiliki ukuran yang sesuai
              this.holderSelectedIdPetugas[index] = this.holderIdPetugas[index2];
            }
          }
        }

      }
    },
    'regexHolder': {
      async handler (newValue) {
        if (newValue.length >= 3) {
          // Panggil fungsi debounce yang sudah didefinisikan
         await this.debouncedWatch(newValue);
          this.regexFilterOn = true
        }
        else if (newValue.length <= 1 && this.regexFilterOn == true) {
          this.regexFilterOn = false
          await this.getContacts()

        }
      }
    }
  },
  methods: {
    onScroll ({ target: { scrollTop, clientHeight, scrollHeight }}) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.limitContact += 10;
        this.getContacts()
        // console.log('SCROLL TO BOTTOM')
      }
    },
    onScrollChat({ target: { scrollTop }}) {
      if (scrollTop === 0) {
        const simpleBarElement = this.$refs.current.$el.querySelector('.simplebar-content-wrapper');
        if (!simpleBarElement) return console.error('SimpleBar content element not found');
        
        const oldScrollHeight = simpleBarElement.scrollHeight;
        this.limitContactChat += 10;

        this.chatUserPage(this.data).then(() => {
          this.$nextTick(() => {
            simpleBarElement.scrollTop = simpleBarElement.scrollHeight - oldScrollHeight;
            this.$refs.current.SimpleBar.recalculate();
          });
        });
      }
    },
    toggleMorph() {
      this.isMorphed = !this.isMorphed;
    },
    updateAssigementSeleceted(index) {
      this.holderSelectedIdPetugas.splice(index, 1)
      this.selectedPetugas.splice(index, 1)
      this.selectedPetugasCount--
    },
    async getDataPetugas() {
      await axios.get(process.env.VUE_APP_URL_FEATHERS + 'analaytic-dashboard-ticket?petugas=true').then(response => {
        this.holderIdPetugas = response.data.idPetugas
        return this.optionsPetugas = response.data.dataPetugas
      })
    },

    /**
     * Get the name of user
     */
    async chatUsername(data) {
      this.limitContact = 10
      this.data = data
      this.customerId = null
      this.enableCustomerApplicant = true
      // this.isChecked = this.data.enableAi
      this.isChecked = this.data.enableAi
      this.contactProfile.name = this.data.customerName == '' ? this.data.profileName : this.data.customerName
      this.contactProfile.number = this.data.wa_id
      this.customerApplicant.phoneNumber = this.data.wa_id
      this.phoneNumberSelected = this.data.wa_id
      await this.resetForm()
      this.username = this.data.profileName + ' - ' + this.data.customerName;
      this.checkedEnableAi()
      this.chatMessagesData = await axios.get(process.env.VUE_APP_URL_FEATHERS + 'waba-chats?waId=' + this.data.wa_id +'&$limit='+this.limitContactChat)
        .then(async response => {
          // console.log(response)
          this.isChatDisabled = response.data.chatDisabled
          this.replyId = response.data.data[response.data.data.length - 1].messageEntryId
          if (data.customerId != null) {
            this.customerId = data.customerId
          }
          await this.getContacts()
          await this.getCustomerApplicant(this.data.wa_id)
          const targetElement = this.$refs.chatScrollTarget;
         targetElement.scrollIntoView({ behavior: 'smooth' });
         await this.handleScroll();
          return response.data.data
        })
       
    },
    async chatUserPage(data) {
      // const nextDay = moment(this,data).add(1, 'days').format()
      this.data = data
      
      this.chatMessagesData = await axios.get(process.env.VUE_APP_URL_FEATHERS + 'waba-chats?waId=' + this.data.wa_id +'&$limit='+this.limitContactChat)
        .then(async response => {
          // console.log(response)
          this.isChatDisabled = response.data.chatDisabled
          this.replyId = response.data.data[response.data.data.length - 1].messageEntryId
          if (data.customerId != null) {
            this.customerId = data.customerId
          }
          return response.data.data
        })
       
    },
    handleChange(value, type) {
      
      if (type === 'second') {
        this.openTime = false;
      }
    },
    async checkedEnableAi() {
      // Menggunakan _.get untuk memastikan bahwa this.data.id tersedia
      const dataId = _.get(this.data, 'id', null); // Jika tidak ada 'id', akan mengembalikan null
      if (!dataId) {
        return;
      }

      if (this.data.enableAi === false && this.isChecked === true) {
        this.data.enableAi = true;
        await axios.patch(`${process.env.VUE_APP_URL_FEATHERS}waba-contacts/${dataId}`, this.data)
          .catch(error => {
            console.error('Error updating enableAi to true:', error);
          });
      }
      if (this.data.enableAi === true && this.isChecked === false) {
        this.data.enableAi = false;
        await axios.patch(`${process.env.VUE_APP_URL_FEATHERS}waba-contacts/${dataId}`, this.data)
          .catch(error => {
            console.error('Error updating enableAi to false:', error);
          });
      }
    },
    async resetForm() {
      this.chatMessagesData = []
      this.replyId = ''
    },
    chatGroup(name, memberno, member) {
      this.username = name;
      this.member = memberno;
      this.usermessage = "Hello";
      this.chatMessagesData = [];
      const currentDate = new Date();

      this.chatMessagesData.push({
        name: member,
        message: this.usermessage,
        time: currentDate.getHours() + ":" + currentDate.getMinutes(),
      });
    },
    async quickChatData() {      
      await axios.get(process.env.VUE_APP_URL_FEATHERS + 'quick-chat-management?indexData=true').then(response => {
       
        this.quickChatDataset = response.data.countQuick
        this.quickChatDisplay = response.data.pesanQuickChat
        
      }).catch(error => {
        Swal.fire(
          'Gagal!',
          error.message,
          'error'
        )
      });
    },

    /**
     * Char form Submit
     */
    // eslint-disable-next-line no-unused-vars
    async formSubmit(e) {
      this.submitted = true;
      this.isChatLoading = true
      // stop here if form is invalid
      this.$v.form.$touch();

      if (this.$v.form.$invalid) {
        this.isChatLoading = false
        return;
      } else {
        const message = this.form.message;
        if (this.isChecked){
          console.log('gashdgashd')
        }
        const objData = {
          message_id: this.replyId,
          wa_id: this.phoneNumberSelected,
          body: {
            text: message
          }
        }
        await axios.post(process.env.VUE_APP_URL_FEATHERS + 'waba-chats', objData)
          .then(async() => {
            // return response.data.total
            this.chatMessagesData = await axios.get(process.env.VUE_APP_URL_FEATHERS + 'waba-chats?waId=' + this.data.wa_id)
              .then(response => {
                // console.log(response)
                this.isChatLoading = false
                this.replyId = response.data.data[response.data.data.length - 1].messageEntryId
                return response.data.data
              })
            this.getContacts()
          }).catch(error => {
            this.isChatLoading = false
            console.log('error', error)
          })
        this.handleScroll();
      }
      this.submitted = false;
      this.form = {};
    },
    handleScroll() {
      if (this.$refs.current.$el) {
        setTimeout(() => {
          this.$refs.current.SimpleBar.getScrollElement().scrollTop = this.$refs.current.SimpleBar.getScrollElement().scrollHeight + 1500;
        }, 500);
      }
    },
    async getContacts() {    
      this.isLoadingChat=true  
      let query = {}
      query.limit = this.limitContact
      query.skip = 0
      const param = {
        ...query
      }
      await axios.get(process.env.VUE_APP_URL_FEATHERS + 'waba-contacts',{params: param})
        .then(response => {
          // console.log('datachat', response.data)
          this.chatData = response.data.data
          this.isLoadingChat=false 
          // return response.data.total
        })
    },
    async getContactsRegex() {      
      let query = {}
      query.limit = this.limitContact
      query.skip = 0
      query.regex = this.regexHolder
      const param = {
        ...query
      }
      await axios.get(process.env.VUE_APP_URL_FEATHERS + 'waba-contacts',{params: param})
        .then(response => {
          // console.log('datachat', response.data)
          this.chatData = response.data.data
          // return response.data.total
        })
    },
    clickPageCustomer() {
      // this.$router.push('/customer/' + this.customerId).catch()
      const routeData = this.$router.resolve('/customer/' + this.customerId);
      window.open(routeData.href, '_blank');
    },
    resetModal() {
      this.customerApplicant.name = ''
      this.customerApplicant.address = ''
      this.submittedCustomer = false
    },
    resetModalTicket() {
      this.submittedTicket = false
      this.ticket = {
        problems: '',
        customerProduct: '',
        customerId: '',
        category: '',
        status: 'open',
        stage: [],
        priority: 'low',
        isCustomer: false
      }
      this.choiceTicket.category = null
      this.choiceTicket.product = null
    },
    async submitCustomerApplicant() {
      this.submittedCustomer = true;
      // stop here if form is invalid
      this.$v.customerApplicant.$touch();
      if (this.$v.customerApplicant.$invalid) {
        // console.log('gagal', this.$v)
        return;
      } else {
        // console.log('berhasil', this.customerApplicant)
        await axios.post(process.env.VUE_APP_URL_FEATHERS + 'customer-applicant', this.customerApplicant)
          .then(async response => {
            console.log('resp', response)
            // return response.data.total
            Swal.fire("Sukses!", "Data sukses disimpan!", "success");
            this.enableCustomerApplicant = false
            this.$refs['modal-customer'].hide()
          }).catch(error => {
            console.error(error)
            Swal.fire("Gagal!", error.message, "error");
          })
      }
    },
    async getCustomerApplicant(noHp) {
      await axios.get(process.env.VUE_APP_URL_FEATHERS + 'customer-applicant?phoneNumber=' + noHp)
        .then(response => {
          if (response.data.total != 0) {
            this.enableCustomerApplicant = false
          }
        })
    },
    showLocation(position) {
      // console.log('posisiton', position)
      this.$router.push({ path: '/coverage-maps', query: { lat: position.latitude, lng: position.longitude } })
    },
    async showModalTicket() {
      this.$refs['modal-ticket'].show()
      await this.getDataPetugas()
      this.listTicketProduct = await axios.get(process.env.VUE_APP_URL_FEATHERS + 'costumer-products?customerId=' + this.customerId)
        .then(response => {
          // console.log('resp prod', response.data)
          return response.data.map(function (val) {
            return {
              text: val.orderId,
              value: val.id
            }
          })
        })
      // console.log(this.listProduct)
    },
    async submitTicket() {
      this.submittedTicket = true;
      
      this.ticket.assignmentGroup = this.holderSelectedIdPetugas
      // stop here if form is invalid
      this.$v.ticket.$touch();
      if (this.$v.ticket.$invalid) {
        console.log('gagal', this.$v.ticket)
        return;
      } else {
        this.ticket.customerId = this.customerId
        const loggeduser = localStorage.getItem('user');
        const jsonUser = JSON.parse(loggeduser)
        this.ticket.stage = {
          code: 'ticketSubmitted',
          createdAt: moment().format(),
          createdBy: jsonUser.username
        }
        // console.log('thic', this.ticket)
        await axios.post(process.env.VUE_APP_URL_FEATHERS + 'ticket', this.ticket)
          .then(async () => {
            // return response.data.total
            Swal.fire("Sukses!", "Data sukses disimpan!", "success");
            this.$refs['modal-ticket'].hide()
          }).catch(error => {
            Swal.fire("Gagal!", error.message, "error");
          })
      }
    },
    async showModalQuickChat() {
      this.$refs.modalManageChat.show()

      
    },
    async testingPass() {
     
      await this.quickChatData()
    },
    async targetedQuickChat(data) {
      
      this.form.message = data.isiPesan
      this.$refs.chatUmumModal.hide()
      this.$refs.QnAModal.hide()
      this.$refs.PembayaranModal.hide()
      this.$refs.gangguanModal.hide()
   
    },
    getTagClass(tagName) {
      switch(tagName) {
        case 'ticket':
          return 'text-danger'; // Merah
        case 'image':
          return 'text-warning'; // Kuning
        case 'billing inquiry':
          return 'text-success'; // Hijau
        default:
          return 'text-muted'; // Abu-abu untuk lainnya
      }
    },
    getBadgeClass(tagName) {
      switch(tagName) {
        case 'ticket':
          return 'bg-danger'; // Merah
        case 'image':
          return 'bg-warning'; // Kuning
        case 'billing inquiry':
          return 'bg-success'; // Hijau
        default:
          return 'bg-secondary'; // Abu-abu untuk lainnya
      }
    },
    getBorderColor(tagName) {
      switch (tagName) {
        case 'ticket':
          return '#dc3545'; // Merah
        case 'image':
          return '#ffc107'; // Kuning
        case 'billing inquiry':
          return '#28a745'; // Hijau
        default:
          return '#6c757d'; // Abu-abu untuk lainnya
      }
    },
    handleTagClick(tag) {
      // Menampilkan deskripsi dan tombol delete dalam SweetAlert2
      Swal.fire({
        title: tag.name,
        text: tag.description,
        showDenyButton: true,
        confirmButtonText: 'Delete',
        denyButtonText: 'Close',
        icon: 'info'
      }).then((result) => {
        if (result.isConfirmed) {
          this.confirmDeleteTag(tag);
        } else if (result.isDenied) {
          // Logika ketika tombol "Close" diklik, tidak ada aksi lebih lanjut yang diperlukan
        }
      });
    },

    confirmDeleteTag(tag) {
      // Konfirmasi akhir untuk penghapusan tag
      Swal.fire({
        title: 'Apakah Anda yakin?',
        text: `Anda ingin menghapus tag "${tag.name}"?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Hapus',
        cancelButtonText: 'Batal'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteTag(tag);
          Swal.fire(
            'Terhapus!',
            `Tag "${tag.name}" telah dihapus.`,
            'success'
          );
        }
      });
    },

    async deleteTag(tag) {
      try {
        const dataId = _.get(this.data, 'id', null); // Menggunakan lodash untuk memastikan ID tersedia
        if (!dataId) {
          return;
        }

        // Mengirim permintaan PATCH dengan parameter delete_tag
        await axios.patch(`${process.env.VUE_APP_URL_FEATHERS}waba-chats/${dataId}`, {
          delete_tag: tag.name // Mengirimkan tag name yang ingin dihapus sebagai parameter
        });

        // Menghapus tag dari data.tags di sisi klien (opsional, tergantung logika aplikasi Anda)
        const tagIndex = this.data.tags.findIndex(t => t.name === tag.name);
        if (tagIndex !== -1) {
          this.data.tags.splice(tagIndex, 1);
        }

        Swal.fire(
          'Terhapus!',
          `Tag "${tag.name}" telah dihapus.`,
          'success'
        );

      } catch (error) {
        console.error('Error deleting tag:', error);
        Swal.fire(
          'Gagal!',
          'Terjadi kesalahan saat menghapus tag.',
          'error'
        );
      }
    },
    async showTroubleShoot () {
      this.cardTroubleShooting = true
      await this.runTroubleShootMethod()
    },
    async runTroubleShootMethod() {
      this.runTroubleShoot = true
      this.resetLoadingState()
      this.data.assignmentGroup = this.holderSelectedIdPetugas
      this.ticket.customerId = this.data.customerId
      this.choiceTicket.customerProduct = this.data.customerProduct
      this.runTroubleShoot = true
      this.troubleShootLoading = true
      this.cardTroubleShooting = true
      await axios.get(process.env.VUE_APP_URL_FEATHERS + 'ticket-analytic?troubleshot=' + this.ticket.customerProduct).then(response => {
        this.cardOptionResult = response.data
        this.StateTroubleShootValue.checkingPayment = this.cardOptionResult.checkingPayment
        this.StateTroubleShootValue.onuIP = this.cardOptionResult.onuIP
        this.StateTroubleShootValue.rating = this.cardOptionResult.rating
        this.showLoadingOverlay()
        return response
      })
      
      //

    },
    resetLoadingState() {
      this.stateTroubleShoot = {
        oltRx: null,
        onuRx: null,
        signal: null,
        onuIP: null,
        upTime: null,
        status: null,
        result: null
      },

        this.loadingStateTroubleShoot = {
          oltRx: true,
          onuRx: true,
          signal: true,
          onuIP: true,
          upTime: true,
          status: true,
          result: true
        },

        this.StateTroubleShootValue = {
          oltRx: null,
          onuRx: null,
          onuSignal: null,
          onuIP: null,
          onuUptime: null,
          onuState: null,
          result: null,
          code: null,
          checkingPayment: null,
          ticketId: null,
          stage_id: null
        }

    },
    async showLoadingOverlay() {
      this.runTroubleShoot = false

      await setTimeout(() => {

      }, 0);
      // Simulate some asynchronous operation, e.g., fetching data
      setTimeout(() => {
        this.loadingStateTroubleShoot.signal = false

        this.StateTroubleShootValue.onuSignal = this.cardOptionResult.onuSignal
        if (this.StateTroubleShootValue.onuSignal == "baik") {
          this.stateTroubleShoot.signal = true
        }
        else {
          this.stateTroubleShoot.signal = false
        }
      }, 0); // Simulating a 2-second loading delay
      setTimeout(() => {

        this.loadingStateTroubleShoot.oltRx = false;

        this.StateTroubleShootValue.oltRx = this.cardOptionResult.oltRx
        if (this.StateTroubleShootValue.onuSignal == "baik") {
          this.stateTroubleShoot.oltRx = true
        }
        else {
          this.stateTroubleShoot.oltRx = false
        }


      }, 0); // Simulating a 2-second loading delay
      setTimeout(() => {
        this.loadingStateTroubleShoot.onuRx = false;
        this.StateTroubleShootValue.onuRx = this.cardOptionResult.onuRx

        if (this.StateTroubleShootValue.onuSignal == "baik") {
          this.stateTroubleShoot.onuRx = true
        }
        else {
          this.stateTroubleShoot.onuRx = false
        }


      }, 0); // Simulating a 2-second loading delay
      setTimeout(() => {


        this.loadingStateTroubleShoot.status = false;
        this.StateTroubleShootValue.onuState = this.cardOptionResult.onuState

        if (this.StateTroubleShootValue.onuState == "working") {
          this.stateTroubleShoot.status = true
        }
        else {
          this.stateTroubleShoot.status = false
        }

      }, 0); // Simulating a 2-second loading delay
      setTimeout(() => {
        this.loadingStateTroubleShoot.upTime = false;
        this.StateTroubleShootValue.onuUptime = this.cardOptionResult.onuUptime
        if (this.StateTroubleShootValue.onuUptime != null) {
          this.stateTroubleShoot.upTime = true
        }
        else {
          this.stateTroubleShoot.upTime = false
        }

      }, 0); // Simulating a 2-second loading delay
      setTimeout(() => {
        this.loadingStateTroubleShoot.onuIP = false;
        this.StateTroubleShootValue.onuIP = this.cardOptionResult.onuIP
        if (this.StateTroubleShootValue.onuIP != null) {
          this.stateTroubleShoot.onuIP = true
        }
        else {
          this.stateTroubleShoot.onuIP = false
        }

      }, 0); // Simulating a 2-second loading delay
      setTimeout(() => {
        this.loadingStateTroubleShoot.status = false;
        this.StateTroubleShootValue.result = this.cardOptionResult.result
        this.runTroubleShoot = true
      }, 0); // Simulating a 2-second loading delay

    },
  },
};
</script>


<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <modalManageQuickChat ref="modalManageChat" @clicked="testingPass"></modalManageQuickChat>
    <div class="d-lg-flex mb-4">
      <div class="chat-leftsidebar card">
        <b-modal id="modal-center" ref="modal-customer" centered title="Form Pelanggan Masuk" title-class="font-18"
          @hide="resetModal">
          <form ref="form" @submit.prevent="submitCustomerApplicant">
            <b-form-group class="mb-3" id="example text" label-cols-sm="2" label-cols-lg="2" label="Nama"
              label-for="text">
              <b-form-input for="text" v-model="customerApplicant.name" :class="{
                'is-invalid': submittedCustomer && $v.customerApplicant.name.$error,
              }"></b-form-input>
              <div v-if="submittedCustomer && $v.customerApplicant.name.$error" class="invalid-feedback">
                <span v-if="!$v.customerApplicant.name.required">Nama tidak boleh kosong!</span>
              </div>
            </b-form-group>
            <b-form-group class="mb-3" id="example text" label-cols-sm="2" label-cols-lg="2" label="Nomor"
              label-for="text">
              <b-form-input disabled for="text" v-model="customerApplicant.phoneNumber" :class="{
                'is-invalid': submittedCustomer && $v.customerApplicant.phoneNumber.$error,
              }"></b-form-input>
              <div v-if="submittedCustomer && $v.customerApplicant.phoneNumber.$error" class="invalid-feedback">
                <span v-if="!$v.customerApplicant.phoneNumber.required">Nomor tidak boleh kosong!</span>
              </div>
            </b-form-group>
            <b-form-group class="mb-3" id="example text" label-cols-sm="2" label-cols-lg="2" label="Alamat"
              label-for="text">
              <b-form-input for="text" v-model="customerApplicant.address" :class="{
                'is-invalid': submittedCustomer && $v.customerApplicant.address.$error,
              }"></b-form-input>
              <div v-if="submittedCustomer && $v.customerApplicant.address.$error" class="invalid-feedback">
                <span v-if="!$v.customerApplicant.address.required">Alamat tidak boleh kosong!.</span>
              </div>
            </b-form-group>
          </form>
          <template #modal-footer>
            <b-button variant="primary" @click="submitCustomerApplicant()">
              Simpan
            </b-button>
          </template>
        </b-modal>
        <b-modal id="modal-center-ticket" ref="modal-ticket" centered title="Form Ticket" title-class="font-18"
          @hide="resetModalTicket">
          <form ref="form" @submit.prevent="submitTicket">
            <!-- <b-form-group
              class="mb-3"
              id="input-group-3"
              label-cols-sm="2"
              label-cols-lg="2"
              label="Produk"
              label-for="input-3"
            >
              <b-form-select
                id="input-3"
                v-model="choiceTicket.product"
                :options="listTicketProduct"
                :class="{
                        'is-invalid': submittedTicket && $v.ticket.customerProduct.$error,
                      }"
              >
              </b-form-select>
              <div
                v-if="submittedTicket && $v.ticket.customerProduct.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.ticket.customerProduct.required"
                  >Produk tidak boleh kosong!</span
                >
              </div>
            </b-form-group> -->
            <b-form-group class="mb-3" id="example text" label-cols-sm="2" label-cols-lg="2" label="Order ID"
              label-for="text">
              <div class="row">
                <div class="col-md-11" style="padding-left: 25px">
                  <select class="form-select" v-model="choiceTicket.product">
                    <option :value="null" disabled>Pilih Order ID</option>
                    <option v-for="option in listTicketProduct" :value="option" :key="option.value">
                      {{ option.text }}
                    </option>
                  </select>
                </div>
              </div>
            </b-form-group>
            <b-form-group class="mb-3" id="example text" label-cols-sm="2" label-cols-lg="2" label="Kategori"
              label-for="text">
              <div class="row">
                <div class="col-md-11" style="padding-left: 25px">
                  <select class="form-select" v-model="choiceTicket.category">
                    <option :value="null" disabled>Pilih Kategori</option>
                    <option v-for="option in categoryTicketList" :value="option.value" :key="option.value">
                      {{ option.text }}
                    </option>
                  </select>
                </div>
              </div>
            </b-form-group>
            <b-form-group  class="mb-2" id="example text" label-cols-sm="2" label-cols-lg="2" label="Jadwal Pemasangan"
              label-for="text" v-if="choiceTicket.category === 'installation'">
              <div class="row">
                <div class="col-md-11 mt-2" style="padding-left: 25px">
                  <div class="pl-0">
                      <b-form-group style="border: 1px solid black; border-radius: 4px; padding: 10px;">

                        <div class="form-group row mb-2" >
                          <label for="horizontal-firstname-input" class="col-sm-3 col-form-label"
                            >Tanggal</label>
                          <div class="col-sm-9">
                            <date-picker v-model="selectedDateTime.date" value-type="format" format="YYYY-MM-DD"
                              type="date" placeholder="Select date" class="mb-1"></date-picker>
                          </div>
                        </div>

                        <div class="form-group row mb-2" >
                          <label for="horizontal-firstname-input" class="col-sm-3 col-form-label"
                            >Waktu</label>
                          <div class="col-sm-9">
                            <date-picker v-model="selectedDateTime.time" value-type="format" type="time"
                              :open.sync="openTime" placeholder="Select time" @change="handleChange"></date-picker>

                          </div>
                        </div>



                      </b-form-group>

                    </div>
                </div>
              </div>
            </b-form-group>
            <div class="form-group row mb-2" >
                    <label for="horizontal-firstname-input" class="col-sm-2 col-form-label">Petugas</label>
                    <div class="col-sm-10">
                      <div v-for="(item, index) in selectedPetugasCount" :key="selectedPetugasCount[index]" class="mt-2" style="padding-left: 12px" >
                        <transition name="fade-in">
                          <div>
                            <b-form-select v-model="selectedPetugas[index]" :options="optionsPetugas" lazy
                              style="width:82%;height:70%;"></b-form-select>
                            <b-button size="sm" variant="danger"
                              style="height: 45%;margin-left: 0.313rem;border-bottom-width: 0px;border-top-width: 0px;border-left-width: 0px;border-right-width: 0px;"
                              class="ml-1" @click="updateAssigementSeleceted(index)"
                              v-if="index != selectedPetugasCount - 1"><i class="dripicons-minus"> </i>
                            </b-button>

                            <b-button size="sm" variant="success"
                              style="height: 45%;margin-left: 0.313rem;border-bottom-width: 0px;border-top-width: 0px;border-left-width: 0px;border-right-width: 0px;"
                              class="ml-1" @click="selectedPetugasCount++" v-if="index == selectedPetugasCount - 1 && selectedPetugas.length != 0" ><i
                                class="dripicons-plus"> </i></b-button>
                          </div>
                        </transition>

                      </div>
                    </div>
            </div>
            <b-form-group class="mb-2" id="example text" label-cols-sm="2" label-cols-lg="2" label="Kendala"
              label-for="text" >
              <div class="row">
                <div class="col-md-11" style="padding-left: 25px">
              <b-form-textarea placeholder="Isi kendala..." for="text" v-model="ticket.problems"  :class="{
                'is-invalid': submittedTicket && $v.ticket.problems.$error,
              }"></b-form-textarea>
              </div>
            </div>
              <div v-if="submittedTicket && $v.ticket.problems.$error" class="invalid-feedback">
                <span v-if="!$v.ticket.problems.required">Kendala tidak boleh kosong!</span>
              </div>
            </b-form-group>
            <div class="mt-2 mb-2 text-center">
            <b-button size="md" variant="info" style="margin-right: 0.313rem;"
             v-if ="choiceTicket.category != 'installation' && choiceTicket.category != null " @click="showTroubleShoot()"> Cek Kendala
            </b-button>

            <div class="mt-2 mb-2 text-center" v-if="cardTroubleShooting">
                    <b-card>
                      <!-- <b-spinner label="Loading..." v-if="troubleShootLoading"></b-spinner> -->
                      <div class="text-start ">
                        <h5 class="mb-3">Hasil Pengecekan</h5>
                        <h6>Optical Network Unit</h6>
                        <ul>

                          <li>
                            <div>
                              <b-input-group class="mb-2">
                                <b-input-group-prepend style="margin-right: 1.25rem;">
                                  <b-button variant="primary" size="sm" class="mr-4" disabled
                                    pill>&nbsp;&nbsp;Signal&nbsp;</b-button>
                                </b-input-group-prepend>

                                <b-form-input size="sm" style="width: 50%;" :state="stateTroubleShoot.signal"
                                  :value="StateTroubleShootValue.onuSignal"></b-form-input>
                                <b-input-group-append>
                                  <div class="loading-overlay" style="margin-top: 6px;"
                                    v-if="loadingStateTroubleShoot.signal"></div>

                                </b-input-group-append>

                              </b-input-group>
                            </div>
                          </li>
                          <li>
                            <div>
                              <b-input-group class="mb-2">
                                <b-input-group-prepend style="margin-right: 1.25rem;">
                                  <b-button variant="primary" size="sm" class="mr-2" disabled
                                    pill>&nbsp;&nbsp;oltRx&nbsp;&nbsp;&nbsp;</b-button>
                                </b-input-group-prepend>

                                <b-form-input size="sm" style="width: 10%;" :state="stateTroubleShoot.oltRx"
                                  class="float-end" :value="StateTroubleShootValue.oltRx"></b-form-input>

                                <b-input-group-append>
                                  <div class="loading-overlay" v-if="loadingStateTroubleShoot.oltRx"></div>

                                </b-input-group-append>
                              </b-input-group>
                            </div>
                          </li>
                          <li>
                            <div>
                              <b-input-group class="mb-2">
                                <b-input-group-prepend style="margin-right: 1.25rem;">
                                  <b-button variant="primary" size="sm" class="mr-4" disabled
                                    pill>&nbsp;&nbsp;onuRx&nbsp;</b-button>
                                </b-input-group-prepend>

                                <b-form-input size="sm" style="width: 50%;" :state="stateTroubleShoot.onuRx"
                                  :value="StateTroubleShootValue.onuRx"></b-form-input>
                                <b-input-group-append>
                                  <div class="loading-overlay" style="margin-top: 6px;"
                                    v-if="loadingStateTroubleShoot.onuRx"></div>

                                </b-input-group-append>

                              </b-input-group>
                            </div>
                          </li>

                          <li>
                            <div>
                              <b-input-group class="mb-2">
                                <b-input-group-prepend style="margin-right: 1.25rem;">
                                  <b-button variant="warning" size="sm" class="mr-4" disabled
                                    pill>&nbsp;&nbsp;Status&nbsp; </b-button>
                                </b-input-group-prepend>

                                <b-form-input size="sm" style="width: 50%;" :state="stateTroubleShoot.status"
                                  :value="StateTroubleShootValue.onuState"></b-form-input>
                                <b-input-group-append>
                                  <div class="loading-overlay" style="margin-top: 6px;"
                                    v-if="loadingStateTroubleShoot.status"></div>

                                </b-input-group-append>

                              </b-input-group>
                            </div>
                          </li>

                          <li>
                            <div>
                              <b-input-group class="mb-2">
                                <b-input-group-prepend style="margin-right: 1.125rem;" :state="true">
                                  <b-button variant="warning" size="sm" class="mr-4" disabled pill>Up Time
                                  </b-button>
                                </b-input-group-prepend>

                                <b-form-input size="sm" style="width: 52%;" :state="stateTroubleShoot.upTime"
                                  :value="StateTroubleShootValue.onuUptime"></b-form-input>
                                <b-input-group-append>
                                  <div class="loading-overlay" style="margin-top: 6px;"
                                    v-if="loadingStateTroubleShoot.upTime"></div>

                                </b-input-group-append>

                              </b-input-group>
                            </div>
                          </li>
                          <li>
                            <div>
                              <b-input-group class="mb-2">
                                <b-input-group-prepend style="margin-right: 1.2rem;">
                                  <b-button variant="warning" size="sm" class="mr-4" disabled pill>&nbsp;Onu
                                    IP&nbsp;</b-button>
                                </b-input-group-prepend>

                                <b-form-input size="sm" style="width: 50%;" :state="stateTroubleShoot.onuIP"
                                  :value="StateTroubleShootValue.onuIP"></b-form-input>
                                <b-input-group-append>
                                  <div class="loading-overlay" style="margin-top: 6px;"
                                    v-if="loadingStateTroubleShoot.onuIP"></div>

                                </b-input-group-append>

                              </b-input-group>
                            </div>
                          </li>
                          <li>
                            <div class="accordion text-center" role="tablist">
                              <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                  <b-button block v-b-toggle.accordion-1 variant="info" disabled>Hasil
                                    TroubleShooting</b-button>

                                </b-card-header>
                                <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                                  <b-card-body style="padding-left:0px;">
                                    <ul>
                                      <li class="mb-3" v-for="items in StateTroubleShootValue.result" :key="items">
                                        <b-card-text class="text-left" align="left">{{ items }}</b-card-text>
                                      </li>

                                    </ul>
                                  </b-card-body>

                                </b-collapse>
                              </b-card>
                            </div>
                          </li>


                        </ul>
                      </div>
                    </b-card>
                  </div>
            </div>


                 
          </form>
          <template #modal-footer>
            <b-button variant="primary" @click="submitTicket()">
              Simpan
            </b-button>
          </template>
        </b-modal>
        <!-- <div class="p-3 px-4">
          <div class="media">
            <div class="align-self-center me-3">
              <img
                src="@/assets/images/users/avatar-4.jpg"
                class="avatar-xs rounded-circle"
                alt
              />
            </div>
            <div class="media-body">
              <h5 class="font-size-16 mt-0 mb-1">
                <a href="#" class="text-dark">
                  Marcus
                  <i
                    class="mdi mdi-circle text-success align-middle font-size-10 ms-1"
                  ></i>
                </a>
              </h5>
              <p class="text-muted mb-0">Available</p>
            </div>

            <div>
              <b-dropdown
                variant="white"
                class="chat-noti-dropdown"
                toggle-class="py-0"
                menu-class="dropdown-menu-end"
                right
              >
                <template v-slot:button-content>
                  <i class="uil uil-ellipsis-h"></i>
                </template>
                <b-dropdown-item href="#">Profile</b-dropdown-item>
                <b-dropdown-item href="#">Edit</b-dropdown-item>
                <b-dropdown-item href="#">Add Contact</b-dropdown-item>
                <b-dropdown-item href="#">Setting</b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div> -->

        <div class="p-3">
          <div class="search-box chat-search-box">
            <div class="position-relative">
              <input 
                type="text" 
                class="form-control bg-light border-light rounded" 
                v-model="regexHolder" 
                placeholder="Search..." 
              />
              <i class="uil uil-search search-icon" size="17"></i>
              <i 
                v-if="regexHolder" 
                class="uil uil-times position-absolute" 
                style="top: 50%; right: 10px; cursor: pointer; transform: translateY(-50%);" 
                @click="regexHolder = ''"
              ></i>
            </div>
          </div>
        </div>

        <div class="pb-3">
          <simplebar  @scroll="onScroll" style="max-height: 470px" id="chat-list">
            <loading :active="isLoadingChat"
                 color="#ff0000"
                 :lock-scroll="true" />
            <!-- <div class="p-4 border-top">
              <div>
                <div class="float-end">
                  <a href class="text-primary">
                    <i class="mdi mdi-plus"></i> New Group
                  </a>
                </div>
                <h5 class="font-size-16 mb-3">
                  <i class="uil uil-users-alt me-1"></i> Groups
                </h5>

                <ul class="list-unstyled chat-list group-list">
                  <li
                    v-for="(group, index) in groupData"
                    :key="index"
                    @click="
                      chatGroup(group.name, group.member, group.membername)
                    "
                    :class="{ active: username == group.name }"
                  >
                    <a href="#">
                      <div class="media align-items-center">
                        <div class="avatar-xs me-3">
                          <span
                            v-if="!group.icon"
                            class="avatar-title rounded-circle bg-soft-primary text-primary"
                            >G</span
                          >
                          <span
                            v-if="group.icon"
                            class="avatar-title rounded-circle bg-soft-primary text-primary"
                          >
                            <i :class="`uil ${group.icon}`"></i>
                          </span>
                        </div>

                        <div class="media-body">
                          <h5 class="font-size-14 mb-0">{{ group.name }}</h5>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div> -->

            <div class="p-1"  >
              <div >
                <!-- <div class="float-end">
                  <a href class="text-primary">
                    <i class="mdi mdi-plus"></i> New Contact
                  </a>
                </div>
                <h5 class="font-size-16 mb-3">
                  <i class="uil uil-user me-1"></i> Contacts
                </h5> -->

                <ul  class="list-unstyled chat-list">
                  <li  v-for="data of chatData" :key="data._id" @click="chatUsername(data)">
                    <a href="#">
                      <div class="media">
                        <div class="user-img align-self-center me-4">
                          <img v-if="data.image" :src="data.image" class="rounded-circle avatar-xs" alt />
                          <div v-if="!data.image" class="avatar-xs align-self-center">
                            <span class="avatar-title rounded-circle bg-soft-primary text-primary">{{
                              data.customerName.charAt(0) }}</span>
                              
                                <!-- <span class="fas fa-circle me-1 text-success" v-if="true"></span>
                                <span class="fas fa-circle me-1 text-success" v-if="true"></span>
                                <span class="fas fa-circle me-1 text-warning" v-if="false"></span>
                                <span class="fas fa-circle me-1 text-danger" v-if="false"></span>
                                <span class="fas fa-circle me-1 text-info"></span>
                                <span class="fas fa-circle me-1 text-dark"></span>
                                <span class="fas fa-circle me-1 "></span> -->
                              
                          </div>
                        </div>

                        <div class="media-body overflow-hidden">
                          <h5 v-if="data.isAnyNewMessage" class="text-truncate font-size-14 mb-1 fw-bold">
                            <i class="mdi mdi-circle text-success align-middle font-size-10 ms-1"></i>
                            {{ data.profileName }} - {{ data.customerName }}
                          </h5>
                          <h5 v-if="!data.isAnyNewMessage" class="text-truncate font-size-14 mb-1 fw-normal">
                            {{ data.profileName }} - {{ data.customerName }}
                          </h5>
                          <p style="width: 220px;" v-if="data.isAnyNewMessage" class="text-truncate mb-0 fw-bold">{{ data.lastMessage }}</p>
                          <p style="width: 220px;" v-if="!data.isAnyNewMessage" class="text-truncate mb-0 fw-normal">{{ data.lastMessage }}
                            
                          </p>
                        </div>
                        <div class="font-size-10 text-truncate text-end">
                          {{ data.messageDate }}
                          <p class="pt-1 text-end">
                            <span 
                              class="fas fa-circle me-1 pt-2" 
                              :class="getTagClass(tag.name)" 
                              v-for="tag in data.tags" 
                              :key="tag.name"
                              style="text-align: right;">
                            </span>
                          </p>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </simplebar>
        </div>
      </div>
      <!-- end chat-leftsidebar -->

      <div class="w-100 user-chat mt-4 mt-sm-0 ms-md-1">
        <div class="card" ref="chatScrollTarget" style="min-height: 475px;">
          <div class="p-3 px-md-4 border-bottom">
            <div class="row">
              <div class="col-md-8 col-8">
                <h5 class="font-size-16 mb-1 text-truncate">
                  <a href="#" class="text-dark">{{ username }}</a>
                </h5>
                <p class="text-muted text-truncate mb-0" v-if="member !== null">
                  <i class="uil uil-users-alt me-1"></i>
                  {{ member !== null ? member : "" }} Members
                </p>
                <p v-if="username !== null">
                  <span 
                    v-for="tag in data.tags" 
                    :key="tag.name" 
                    class="badge me-1 custom-tooltip" 
                    :class="getBadgeClass(tag.name)"
                    @click="handleTagClick(tag)"
                  >
                    {{ tag.name }}
                    <span 
                      class="tooltip-text" 
                      :style="{ borderColor: getBorderColor(tag.name) }"
                    >
                      {{ tag.description }}
                    </span>
                  </span>
                </p>
              
              </div>
              <div class="col-md-4 col-4" v-if="phoneNumberSelected != null">
                <ul class="list-inline user-chat-nav text-end mb-0">
                  <!-- <li class="list-inline-item">
                    <b-dropdown
                      right
                      menu-class="dropdown-menu-md dropdown-menu-end"
                      variant="white"
                    >
                      <template v-slot:button-content>
                        <i class="uil uil-search"></i>
                      </template>

                      <form class="p-2">
                        <div>
                          <input
                            type="text"
                            class="form-control rounded"
                            placeholder="Search..."
                          />
                        </div>
                      </form>
                    </b-dropdown>
                  </li> -->
                  <li class="list-inline-item d-flex justify-content-end align-items-center">
                    <!-- <b-form-checkbox v-model="" name="check-button" right toggle-class="nav-btn" class="mt-2">
                      &nbsp;&nbsp;Enable Auto Reply
                    </b-form-checkbox> -->
                    <div class="text-truncate md">
                      Enable Auto Reply
                    </div>
                      <switches
                      v-model="isChecked"
                      type-bold="true"
                      :color="isChecked ? 'success' : 'danger'"
                      class="mb-0 mt-2 pt-1 custom-switch"
                    >
                    </switches>
                    
                    <!-- <b-dropdown right toggle-class="nav-btn" variant="white" class="ml-2">
                      <template v-slot:button-content>
                        <i class="uil uil-ellipsis-h"></i>
                      </template>
                      <a v-if="customerId == null && enableCustomerApplicant" class="dropdown-item" href="#"
                        v-b-modal.modal-center>Pelanggan Masuk </a>
                      <a v-if="customerId != null" class="dropdown-item" href="#" @click="clickPageCustomer">Halaman
                        Pelanggan </a>
                      <a v-if="customerId != null" class="dropdown-item" href="#" @click="showModalTicket">Buat Ticket
                      </a>
                    </b-dropdown> -->
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="px-lg-2 chat-users">
            <div class="chat-conversation p-3" style="min-height: 470px;">
              <simplebar style="max-height: 455px" id="containerElement" ref="current">
                <ul class="list-unstyled mb-0">
                </ul>
              </simplebar>
              <simplebar style="max-height: 470px" id="containerElement1" v-if="phoneNumberSelected != null" ref="current" @scroll="onScrollChat">
                <ul class="list-unstyled mb-0">
                  <!-- <li class="chat-day-title">
                    <div class="title">Yesterday</div>
                  </li> -->
                  <li v-for="data of chatMessagesData" :key="data.messageId"
                    :class="{ right: data.messageType === 'sent' }">
                    <div v-if="data.messageBody.type == 'text'" class="conversation-list">
                      <div class="ctext-wrap" style="max-width: 450px;">
                        <div class="ctext-wrap-content">
                          <!-- <h5 class="font-size-14 conversation-name">
                             <a href="#" class="text-dark" v-if="data.messageType=='sent'">{{ data.messageDate }}</a> 
                            <a href="#" class="text-dark" >{{ data.messageBody.text }}</a>
                            <span
                              class="d-inline-block font-size-12 text-muted ms-2"
                              >{{ data.messageDate }}</span
                            >
                          </h5> -->
                          <p class="font-size-14 conversation-name thick">{{ data.messageBody.text }}
                          </p>
                          <span v-if="data.messageType == 'sent'" class="d-inline-block font-size-12 text-muted ms-2 right" style="float: right;"> Replied by: {{
                            data.messageUser }} - {{
                            data.messageDate }}</span>
                          <span v-if="data.messageType == 'received'" class="d-inline-block font-size-12 text-muted ms-2 right" style="float: right;"> {{
                            data.messageDate }}</span>
                        </div>
                        <!-- <b-dropdown class="align-self-start" variant="white">
                          <template v-slot:button-content>
                            <i class="uil uil-ellipsis-v"></i>
                          </template>
                          <a class="dropdown-item" href="#">Copy</a>
                          <a class="dropdown-item" href="#">Save</a>
                          <a class="dropdown-item" href="#">Forward</a>
                          <a class="dropdown-item" href="#">Delete</a>
                        </b-dropdown> -->
                      </div>
                    </div>
                    <div v-if="data.messageBody.type == 'image'" class="conversation-list">
                      <div class="ctext-wrap" style="max-width: 450px;">
                        <div class="ctext-wrap-content">
                          <!-- <h5 class="font-size-14 conversation-name">
                             <a href="#" class="text-dark" v-if="data.messageType=='sent'">{{ data.messageDate }}</a> 
                            <a href="#" class="text-dark" >{{ data.messageBody.text }}</a>
                            <span
                              class="d-inline-block font-size-12 text-muted ms-2"
                              >{{ data.messageDate }}</span
                            >
                          </h5> -->
                          <img
                            v-if="data.messageBody.urlFile != undefined || data.messageBody.urlFile != '' || data.messageBody.urlFile != '-'"
                            :src=data.messageBody.urlFile class="img-fluid img-thumbnail" alt
                            style="max-height: 500px;max-width: 500px;" />
                          <p v-if="data.messageBody.text != '-'" class="font-size-14 conversation-name thick"> {{
                            data.messageBody.text }}
                          </p>
                          <span class="d-inline-block font-size-12 text-muted ms-2 right" style="float: right;">{{
                            data.messageDate }}</span>
                        </div>
                      </div>
                    </div>
                    <div v-if="data.messageBody.type == 'location'" class="conversation-list">
                      <div class="ctext-wrap" style="max-width: 450px;">
                        <div class="ctext-wrap-content">
                          <p class="font-size-14 conversation-name thick">Membagikan Lokasi
                          </p>
                          <b-button size="sm" variant="primary" class="mb-2" @click="showLocation(data.messageBody.text)">
                            Lihat Lokasi
                          </b-button>
                          <span class="d-inline-block font-size-12 text-muted ms-2 right" style="float: right;">{{
                            data.messageDate }}</span>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </simplebar>
            </div>
          </div>

          <div class="p-3 chat-input-section">
            <form @submit.prevent="formSubmit" class="row" v-if="phoneNumberSelected != null">
              <div class="col-10">
                <div class="position-relative input-with-icon">
                  <input v-if="isChatDisabled" disabled type="text" v-model="form.message"
                    class="form-control chat-input rounded" placeholder="Pelanggan belum mengirimkan pesan...." :class="{
                      'is-invalid': submitted && $v.form.message.$error,
                    }" />
                  <input v-if="!isChatDisabled" type="text" v-model="form.message" class="form-control chat-input rounded"
                    placeholder="Ketik pesan disini..." :class="{
                      'is-invalid': submitted && $v.form.message.$error,
                    }" />


                  <b-dropdown size="lg" variant="link" dropup class="btn btn-default icon" no-caret v-if="!isChatDisabled">
                    <template #button-content>
                      <span class="fab fa-rocketchat" style="opacity: 0.5;"></span>
                    </template>
                    <b-dropdown-header id="dropdown-header-label" style="background-color: #c6c6c6;">
                      Management Chat<span class="fas fa-plus-circle float-end mt-1" size="xl"
                        @click="showModalQuickChat"></span>
                    </b-dropdown-header>

                    <b-dropdown-item-button id="popover-target-1"  v-b-modal="'chatUmumModal'">Chat Umum <span class="font-weight-bold float-end"
                        style="color: #0980f6;"><strong>{{ quickChatDataset.chatUmum }}</strong></span>
                    </b-dropdown-item-button>
                    <b-dropdown-item-button id="popover-target-2" v-b-modal="'QnAModal'">Q&A <span class="font-weight-bold float-end"
                        style="color: #0980f6;"><strong>{{ quickChatDataset.questionAndAnswer }}</strong></span>
                    </b-dropdown-item-button>
                    <b-dropdown-item-button id="popover-target-2" v-b-modal="'PembayaranModal'">Pembayaran <span class="font-weight-bold float-end"
                        style="color: #0980f6;"><strong>{{ quickChatDataset.pembayaran }}</strong></span>
                    </b-dropdown-item-button>
                     <b-dropdown-item-button id="popover-target-2" v-b-modal="'gangguanModal'">Gangguan<span class="font-weight-bold float-end"
                        style="color: #0980f6;"><strong >{{ quickChatDataset.gangguan }}</strong></span>
                    </b-dropdown-item-button>
                    
                  </b-dropdown>

                  <div v-if="submitted && $v.form.message.$error" class="invalid-feedback">
                    <span v-if="!$v.form.message.required">Harap mengisi pesan!</span>
                  </div>
                  
                </div>
              </div>

              <div class="col-2" style="top: 1.25rem;">

                <button v-if="!isChatLoading" :disabled="isChatDisabled" type="submit"
                  class="btn btn-primary chat-send w-md waves-effect waves-light">
                  <span class="d-none d-sm-inline-block me-2">Kirim</span>
                  <i class="mdi mdi-send float-start"></i>
                </button>

                <b-spinner v-if="isChatLoading" class="xs-1" variant="primary"></b-spinner>
              </div>
            </form>
          </div>
        </div>
      </div>
<div class="col-auto" v-if="contactProfile.number != ''">
  
      <div>
        <b-card style="min-height: 41.6313rem;min-width: 385px;"  md="3">
          <b-card> 
          <b-card-body class="pt-0 pb-0"> 
            <b-card-title>
              <h5 class="card-title text-center pb-0 pt-0">Profil Kontak</h5>
            </b-card-title>
          </b-card-body>
          <b-card-body>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <h5 class="card-title ">Tentang</h5>
                <div class="row">
                  <div class="col-1">
                    <i class="fas fa-phone-alt"></i>
                  </div>
                  <div class="col">
                    {{ contactProfile.number }}
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-1">
                    <i class="far fa-user"></i>
                  </div>
                  <div class="col">
                    {{ contactProfile.name }}
                  </div>
                </div>
              </li>
              <!-- <li class="list-group-item">
                <h5 class="card-title ">Catatan</h5>
                <textarea
                  class="form-control"
                  :maxlength="225"
                  rows="3"
                  placeholder="Tulis Catatan"
                ></textarea>
              </li> -->
            </ul>
            
          </b-card-body>
        </b-card>
        <b-card >
          <b-card-title>
              
            </b-card-title>
            <p/>
            <div id="customer-action-accordion" flush>
          <b-card no-body v-b-toggle.customer-action-1 
                block 
                variant="link" >
            <b-card-header header-tag="header" role="tab">
              <div
                
                class="text-center d-flex justify-content-between align-items-center">
                <h5 class="card-title text-center pb-0 pt-0">Customer Action</h5>
                <span class="float-end" :class="{'fas fa-chevron-up': isAccordionOpen, 'fas fa-chevron-down': !isAccordionOpen}"></span>
            </div>
            </b-card-header>
            <b-collapse 
              id="customer-action-1" 
              accordion="customer-action-accordion" 
              role="tabpanel"
              @show="isAccordionOpen = true" 
              @hide="isAccordionOpen = false">
              <b-card-body v-if="customerId !== null">
                <b-button class="me-2" variant="primary" @click="clickPageCustomer">Halaman Pelanggan</b-button>
                <b-button class="me-2" style="width: 35%;" variant="warning" @click="showModalTicket">Buat Tiket</b-button>
              </b-card-body>

              <b-card-body v-if="customerId === null" class="d-flex justify-content-center align-items-center flex-column">
                <b-button class="me-2" variant="primary" v-b-modal.modal-center>Pelanggan Masuk</b-button>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        </b-card>
        </b-card>
      </div>
    
    </div>
      <b-modal ref="chatUmumModal" id="chatUmumModal" size="lg" scrollable :hide-footer=true centered title="List Chat Umum">
        <div class="table-responsive mt-4 mb-0">
            <label class="d-inline-flex align-items-center fw-normal">
            Show&nbsp;
          </label>
            <b-form-select v-model="perPage" :options="pageOptions" size="sm" class="mr-6"></b-form-select>&nbsp;entries &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

             <b-table striped hover ref="tableQuickChatUmum"
            :items="quickChatDisplay.chatUmum"
            :fields="fieldsQuickChat"
            :key="items.isiPesan"
            table-class="table table-centered datatable table-card-list"
            thead-tr-class="bg-secondary"
            tbody-tr-class="custom-border"
            responsive="md" 
            :per-page="perPage" 
            :current-page="currentPage"
             >
            <template #cell(actions)="row">
              <b-button @click="targetedQuickChat(row.item)" size="sm" variant="success" class="mdi mdi-chat-plus-outline" style="margin-right: 0.313rem;" >
              </b-button>
            </template>
            
          </b-table>
          </div>


      </b-modal>
      <b-modal ref="QnAModal" id="QnAModal" size="lg" scrollable :hide-footer=true centered title="List Q&A">
        <div class="table-responsive mt-4 mb-0">
            <label class="d-inline-flex align-items-center fw-normal">
            Show&nbsp;
          </label>
            <b-form-select v-model="perPage" :options="pageOptions" size="sm" class="mr-6"></b-form-select>&nbsp;entries &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            
             <b-table striped hover ref="tableQuickQ&A"
            :items="quickChatDisplay.questionAndAnswer"
            :fields="fieldsQuickChat"
            :key="items.isiPesan"
            table-class="table table-centered datatable table-card-list"
            thead-tr-class="bg-secondary"
            tbody-tr-class="custom-border"
            responsive="md" 
            :per-page="perPage" 
            :current-page="currentPage">
            <template #cell(actions)="row">
              <b-button @click="targetedQuickChat(row.item)" size="sm" variant="success" class="mdi mdi-chat-plus-outline" style="margin-right: 0.313rem;" >
              </b-button>
            </template>
            
          </b-table>
          </div>

      </b-modal>
      <b-modal ref="PembayaranModal" id="PembayaranModal" size="lg" scrollable :hide-footer=true centered title="List Pembayaran">
        <div class="table-responsive mt-4 mb-0">
            <label class="d-inline-flex align-items-center fw-normal">
            Show&nbsp;
          </label>
            <b-form-select v-model="perPage" :options="pageOptions" size="sm" class="mr-6"></b-form-select>&nbsp;entries &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            
           
             <b-table striped hover ref="tableQuickPembayaran"
            :items="quickChatDisplay.pembayaran"
            :fields="fieldsQuickChat"
            :key="items.isiPesan"
            table-class="table table-centered datatable table-card-list"
            thead-tr-class="bg-secondary"
            tbody-tr-class="custom-border"
            responsive="md" 
            :per-page="perPage" 
            :current-page="currentPage">
            <template #cell(actions)="row">
              <b-button @click="targetedQuickChat(row.item)" size="sm" variant="success" class="mdi mdi-chat-plus-outline" style="margin-right: 0.313rem;" >
              </b-button>
            </template>
            
          </b-table>
          </div>

      </b-modal>
      <b-modal ref="gangguanModal" id="gangguanModal" size="lg" scrollable :hide-footer=true centered title="List Gangguan">
        <div class="table-responsive mt-4 mb-0">
            <label class="d-inline-flex align-items-center fw-normal">
            Show&nbsp;
          </label>
            <b-form-select v-model="perPage" :options="pageOptions" size="sm" class="mr-6"></b-form-select>&nbsp;entries &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            
           
             <b-table striped hover ref="tableQuickGangguan"
            :items="quickChatDisplay.gangguan"
            :fields="fieldsQuickChat"
            :key="items.isiPesan"
            table-class="table table-centered datatable table-card-list"
            thead-tr-class="bg-secondary"
            tbody-tr-class="custom-border"
            responsive="md" 
            :per-page="perPage" 
            :current-page="currentPage">
            <template #cell(actions)="row">
              <b-button @click="targetedQuickChat(row.item)" size="sm" variant="success" class="mdi mdi-chat-plus-outline" style="margin-right: 0.313rem;" >
              </b-button>
            </template>
            
          </b-table>
          </div>

      </b-modal>
    </div>
  </Layout>
  <!-- End d-lg-flex  -->
</template>
<style>
p {
  text-align: left;
}

p.thick {
  font-weight: 500;
}

span.right {
  text-align: left;
}

.input-with-icon {
  /* causes absolute icon div to be positioned correctly */
  position: auto;

  width: auto;
  height: 3.2rem;
  box-sizing: border-box;
}

.input-with-icon .form-control {
  height: 100%;
  width: 100%;
  padding-right: 3rem;
  box-sizing: border-box;
}

.input-with-icon .icon {
  position: absolute;


  right: 0rem;
  top: 0.3rem;
  width: 2.6rem;
  height: 2.6rem;
  border-radius: 0.3rem;


  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}


.input-with-icon {
  margin: 0.8rem;
}

.input-with-icon .form-control {
  padding-left: 1.2rem;
  padding-top: 0.6rem;

}

.input-with-icon .icon {
  border: 0.1px solid rgb(216, 216, 216);
}

/* .popover-body {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  white-space: pre-wrap;
  min-width: 400px;
  border: 0.2px solid rgb(255, 254, 254);
  box-shadow: 0.5em 0.5em 1em rgba(105, 104, 104, 0.4),
    inset 0.05em 0.05em rgba(255, 255, 255, 1);

} */



.ol-cards,
.ol-cards *,
.ol-cards *::before,
.ol-cards *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.ol-cards {
  --ol-cards-color-bg: var(--color-bg);
  list-style-type: none;
  display: flex;
  flex-direction: column;
  margin: 0;
  max-width: 55em;
  min-width: 19.8em;

  counter-reset: ol-cards-counter;
}

.ol-cards li {
  display: grid;
  padding: 1em 2em 0.8em 0;
  row-gap: 0.5em;
  column-gap: 1em;
  box-shadow: 0.5em 0.5em 1em rgba(187, 186, 186, 0.4),
    inset 0.05em 0.05em rgba(255, 255, 255, 1);
  counter-increment: ol-cards-counter;
  text-align: left;
  background-color: var(--ol-cards-color-bg);
}
.custom-tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip-text {
  visibility: hidden;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Posisi di atas teks */
  left: 50%;
  transform: translateX(-50%); /* Sentralisasi tooltip secara horizontal */
  border: 2px solid; /* Border akan diatur secara dinamis dengan inline style */
  opacity: 0;
  transition: opacity 0.3s;
  white-space: nowrap; /* Mencegah teks menjadi multi-baris */
  max-width: 300px; /* Membatasi lebar maksimum */
}

.custom-tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.custom-switch {
  transform: scale(0.65); /* Sesuaikan nilai skala sesuai kebutuhan */
  position: relative;
  width: 60px; /* Sesuaikan lebar switch */
  height: 30px; /* Sesuaikan tinggi switch */
}
.switch-label {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px; /* Sesuaikan ukuran font */
  color: white; /* Warna teks */
}

.switch-on {
  color: green; /* Warna teks On */
}

.switch-off {
  color: red; /* Warna teks Off */
}


/* li:hover {
  background-color: rgb(176, 177, 178);
} */
</style>